import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';


const URL = "https://www.devcarlscan-api.devcarl.fr/";

const Reprendre: React.FC = () => {
 
  return (
    <div>
      Reprise
    </div>
  );
};

export default Reprendre;
