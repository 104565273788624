import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Accueil from './components/Accueil';
import Manga from './components/Manga';
import Chapitre from './components/Chapitre';
import Reprendre from './components/Reprendre';

import "./App.css"
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Accueil />} />
        <Route path="/manga/:titre" element={<Manga />} />
        <Route path="/manga/:titre/chapitre/:chapitreId" element={<Chapitre />} />
        <Route path="/reprendre" element={<Reprendre />} />
      </Routes>
    </Router>
  );
}

export default App;
