import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

type Manga = {
  manga_name: string;
  manga_chapter_length: number;
  manga_cover: string;
};

const URL = "https://www.devcarlscan-api.devcarl.fr/";

const Accueil: React.FC = () => {
  const [mangas, setMangas] = useState<Manga[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [filteredMangas, setFilteredMangas] = useState<Manga[]>([]);
  const [hoveredManga, setHoveredManga] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true); // Variable d'état pour le chargement

  useEffect(() => {
    const fetchMangas = async () => {
      try {
        const response = await axios.get('https://www.devcarlscan-api.devcarl.fr/mangas/');
        setMangas(response.data);
        filterMangas(response.data, searchValue); // Appliquer le filtrage initial
        setIsLoading(false); // Mettre isLoading à false une fois les données chargées
        console.log(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des mangas :', error);
      }
    };

    fetchMangas();
  }, []);

  const filterMangas = (mangas: Manga[], searchTerm: string) => {
    const filtered = mangas.filter(manga =>
      manga.manga_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredMangas(filtered);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    filterMangas(mangas, event.target.value);
  };

  const handleMouseEnter = (mangaName: string) => {
    console.log(mangaName)
    setHoveredManga(mangaName);
  };

  const handleMouseLeave = () => {
    setHoveredManga(null);
  };

  return (
    <div>
      <div className='header'>
        <div className='left-box'>
          <div className='logo'></div>
          <input
            type="text"
            placeholder="Recherche..."
            value={searchValue}
            onChange={handleSearchChange}
            className='search-bar'
          />
        </div>
        <div className='right-box'>
          <Link
            to={`/reprendre`}
          >Reprendre
          </Link>
        </div>

      </div>
      <div className="mangas-view">
        {isLoading ? ( // Afficher le loading si isLoading est true
          <div className="loading"></div>
        ) : (
          (searchValue !== '' ? filteredMangas : mangas).map(manga => (
            <Link
              className={`mangas-card ${hoveredManga === manga.manga_name ? 'hovered' : ''}`}
              to={`/manga/${manga.manga_name}`}
              key={manga.manga_name}
              onMouseEnter={() => handleMouseEnter(manga.manga_name)}
              onMouseLeave={handleMouseLeave}
            >
              <div className="img-box">
                <img src={URL + manga.manga_cover} alt={manga.manga_name} />
              </div>
              <div className="infos-box">
                <h2>{manga.manga_name.replace(/[^\w\s]/gi, ' ')}</h2>
                <p>Chapitres : {manga.manga_chapter_length}</p>
              </div>
            </Link>
          ))
        )}
      </div>
    </div>
  );
};

export default Accueil;
