import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const URL = 'https://www.devcarlscan-api.devcarl.fr/';

type MangaDetails = {
  titre: string;
  statut: string;
  dateSortie: string;
  genres: string[];
  synopsis: string;
  typeLecture: string;
};

type MangaChapter = {
  chapter_name: string;
  chapter_pages: string[];
}[];

type MangaExtraInfos = {
  manga_chapter_length: number;
  manga_cover: string;
  manga_name: string;
};

interface ContinueReading {
  manga_title: string;
  chapter_name: string;
  chapter_page: number;
}


const Manga: React.FC = () => {
  const { titre } = useParams<{ titre: string }>();
  const navigate = useNavigate();
  const [mangaDetails, setMangaDetails] = useState<MangaDetails | null>(null);
  const [mangaChapters, setMangaChapters] = useState<MangaChapter | null>(null);
  const [selectedChapter, setSelectedChapter] = useState<string>('');
  const [mangasExtraInfos, setMangasExtraInfos] = useState<MangaExtraInfos | null>(null);
  const [continueReading, setContinueReading] = useState<ContinueReading[]>([]);
  const storedContinueReading = localStorage.getItem('continueReading');
  
  useEffect(() => {
    const storedContinueReading = localStorage.getItem('continueReading');
    if (storedContinueReading) {
      setContinueReading(JSON.parse(storedContinueReading));
    }
  }, []);


  useEffect(() => {
    const fetchMangaDetails = async () => {
      if (titre) {
        try {
          const response = await axios.get(`https://www.devcarlscan-api.devcarl.fr/mangas/${titre}/`);
          setMangaDetails(response.data.details);
          if (response.data.chapters) {
            const data = response.data.chapters;
            const sortedChapters = data.sort((a: { chapter_name: string }, b: { chapter_name: string }) =>
              parseFloat(a.chapter_name) - parseFloat(b.chapter_name)
            );

            setMangaChapters(sortedChapters);
          }
          setMangasExtraInfos(response.data.manga);
          console.log(response.data);
        } catch (error) {
          console.error(`Erreur lors de la récupération des détails du manga avec le titre "${titre}" :`, error);
        }
      }
    };

    fetchMangaDetails();
  }, [titre]);

  useEffect(() => {
    if (mangaChapters && mangaChapters.length > 0) {
      setSelectedChapter(mangaChapters[0].chapter_name);
    }
  }, [mangaChapters]);

  const handleChapterSelect = (chapterName: string) => {
    setSelectedChapter(chapterName);
  };

  const handleLaunchChapter = () => {
    if (selectedChapter) {
      navigate(`/manga/${titre}/chapitre/${selectedChapter}`, {
        state: {
          mangaDetails,
          mangasExtraInfos,
          mangaChapters,
        },
      });
    }
  };

  const handleContinueReading = () => {
    console.log(storedContinueReading); // Check the value of storedContinueReading
    console.log(titre)
    if (storedContinueReading) {
      const continueReadingData: ContinueReading[] = JSON.parse(storedContinueReading);
      const mangaData = continueReadingData.find((data) => data.manga_title === titre);

      if (mangaData) {
        console.log("yoo")
        navigate(`/manga/${mangaData.manga_title}/chapitre/${mangaData.chapter_name}/#${mangaData.chapter_page}`, {
          state: {
            mangaDetails,
            mangasExtraInfos,
            mangaChapters,
          },
        });
      }
    }
  };
  
  

return (
  <div className='wrapper-manga'>
    {mangaDetails && mangasExtraInfos ? (
      <div className='manga-bio'>
        <div className='manga-img'>
          <img src={URL + mangasExtraInfos.manga_cover} alt={mangasExtraInfos.manga_name} />
        </div>
        <div className='manga-content'>
          <h1 className='capitalize'>{mangaDetails.titre}</h1>
          <h2>{mangasExtraInfos.manga_chapter_length} Chapitres</h2>
          <p>{mangaDetails.statut}</p>
          <p>Date de sortie : {mangaDetails.dateSortie}</p>
          <h3>Genres :</h3>
          <ul>
            {mangaDetails.genres.map((genre, index) => (
              <li key={index}>{genre}</li>
            ))}
          </ul>
          <p>Synopsis : {mangaDetails.synopsis}</p>
          {mangaChapters ? (
            <div className='input-box'>
              <button className='cta-chapter' onClick={()=>handleContinueReading()}>
                Reprendre la lecture
              </button>
              <h3 className='chapter-title'>Chapitres :</h3>
              <div className='btn-box'>
                <select
                  className='select-chapter'
                  value={selectedChapter}
                  onChange={(e) => handleChapterSelect(e.target.value)}
                >
                  {mangaChapters.map((chapter: { chapter_name: string; chapter_pages: string[]; }, index: number) => (
                    <option key={index} value={chapter.chapter_name}>
                      {chapter.chapter_name}
                    </option>
                  ))}
                </select>
                <button className='cta-chapter' onClick={handleLaunchChapter}>
                  Lancer le chapitre
                </button>
              </div>
            </div>
          ) : (
            <p>Chargement ...</p>
          )}
        </div>
      </div>
    ) : (
      <p>Chargement...</p>
    )}
  </div>
);
};

export default Manga;
