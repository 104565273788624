import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, Link, useParams } from 'react-router-dom';
import axios from "axios";

interface MangaChapter {
  chapter_name: string;
  chapter_pages: string[];
}

interface ContinueReading {
  manga_title: string;
  chapter_name: string;
  chapter_page: number;
}

type MangaDetails = {
  titre: string;
  statut: string;
  dateSortie: string;
  genres: string[];
  synopsis: string;
  typeLecture: string;
};

type MangaExtraInfos = {
  manga_chapter_length: number;
  manga_cover: string;
  manga_name: string;
};

const Chapitre: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(0);
  const [fullMod, setFullMod] = useState(true);
  const continueReadingDataString = localStorage.getItem('continueReading');
  const [continueReadingData, setContinueReadingData] = useState<ContinueReading[]>([]);
  
  const [mangaDetails, setMangaDetails] = useState<MangaDetails | null>(null);
  const [mangaChaptersData, setMangaChaptersData] = useState<MangaChapter | null>(null);
  const [mangasExtraInfos, setMangasExtraInfos] = useState<MangaExtraInfos | null>(null);

  const { titre } = useParams<{ titre: string }>();

  useEffect(() => {
    const fetchMangaDetails = async () => {
      if (titre) {
        try {
          const response = await axios.get(`https://www.devcarlscan-api.devcarl.fr/mangas/${titre}/`);
          setMangaDetails(response.data.details);
          if (response.data.chapters) {
            const data = response.data.chapters;
            const sortedChapters = data.sort((a: { chapter_name: string }, b: { chapter_name: string }) =>
              parseFloat(a.chapter_name) - parseFloat(b.chapter_name)
            );

            setMangaChaptersData(sortedChapters);
          }
          setMangasExtraInfos(response.data.manga);
          console.log(response.data);
        } catch (error) {
          console.error(`Erreur lors de la récupération des détails du manga avec le titre "${titre}" :`, error);
        }
      }
    };

    fetchMangaDetails();
  }, [titre]);

  var { mangaChapters } = location.state || {};

  if (mangaChapters === undefined) {
    mangaChapters = mangaChaptersData
  }

  console.log(mangaChapters)



  const mangaTitle = location.pathname.split('/')[2];

  useEffect(() => {
    const storedContinueReadingData = localStorage.getItem('continueReading');
    if (storedContinueReadingData) {
      setContinueReadingData(JSON.parse(storedContinueReadingData));
    } else {
      // Les données n'existent pas dans le localStorage, initialiser avec une valeur par défaut
      const initialData: ContinueReading[] = [
        {
          manga_title: mangaTitle,
          chapter_name: "1",
          chapter_page: 0,
        },
      ];
      setContinueReadingData(initialData);
      localStorage.setItem('continueReading', JSON.stringify(initialData));
    }
  }, []);
  


  useEffect(() => {
    const fetchData = async () => {
      const currentChapter = location.pathname.split('/')[4];
      const currentPageNum = currentPage + 1;
  
      const existingContinueReadingData = JSON.parse(localStorage.getItem('continueReading')!);
      console.log(existingContinueReadingData)

      const existingMangaData = existingContinueReadingData.find((data:any) => data.manga_title === mangaTitle);
      console.log(continueReadingData)

      if (!existingMangaData) {
        console.log("existe pas");
  
        // Si le manga n'existe pas, l'ajouter à continueReadingData
        const newMangaData = {
          manga_title: mangaTitle,
          chapter_name: currentChapter,
          chapter_page: currentPageNum,
        };
        console.log(localStorage.getItem('continueReading'));
  
  
        if (existingContinueReadingData) {
          const mangaIndex = existingContinueReadingData.findIndex((data: any) => data.manga_title === mangaTitle);
          if (mangaIndex !== -1) {
            // Le manga existe déjà, mettre à jour les informations
            const updatedContinueReadingData = [...existingContinueReadingData];
            updatedContinueReadingData[mangaIndex] = newMangaData;
            setContinueReadingData(updatedContinueReadingData);
            localStorage.setItem('continueReading', JSON.stringify(updatedContinueReadingData));
          } else {
            // Le manga n'existe pas, l'ajouter à la liste
            const updatedContinueReadingData = [...existingContinueReadingData, newMangaData];
            setContinueReadingData(updatedContinueReadingData);
            localStorage.setItem('continueReading', JSON.stringify(updatedContinueReadingData));
          }
        } else {
          // Aucune donnée existante, ajouter le premier manga
          const updatedContinueReadingData = [newMangaData];
          setContinueReadingData(updatedContinueReadingData);
          localStorage.setItem('continueReading', JSON.stringify(updatedContinueReadingData));
        }
  
      } else if (getNumericChapter(existingMangaData.chapter_name) < getNumericChapter(currentChapter)) {
        // Mettre à jour les informations du manga avec le nouveau chapitre
        const updatedContinueReadingData = continueReadingData.map((data) => {
          if (data.manga_title === mangaTitle) {
            return {
              ...data,
              chapter_name: currentChapter,
              chapter_page: currentPageNum,
            };
          } else {
            return data;
          }
        });
  
        setContinueReadingData(updatedContinueReadingData);
        localStorage.setItem('continueReading', JSON.stringify(updatedContinueReadingData));
        console.log(localStorage.getItem('continueReading'));
      } else if (existingMangaData.chapter_name === currentChapter && existingMangaData.chapter_page < currentPageNum) {
        // Mettre à jour uniquement la page actuelle pour le même chapitre
        const updatedContinueReadingData = continueReadingData.map((data) => {
          if (data.manga_title === mangaTitle && data.chapter_name === currentChapter) {
            return {
              ...data,
              chapter_page: currentPageNum,
            };
          } else {
            return data;
          }
        });
  
        setContinueReadingData(updatedContinueReadingData);
        localStorage.setItem('continueReading', JSON.stringify(updatedContinueReadingData));
        console.log(localStorage.getItem('continueReading'));
      }
    };
  
    fetchData();
  }, [mangaTitle, location.pathname, currentPage]);
  

  function getNumericChapter(chapterName: string): number {
    const numericPart = chapterName.match(/\d+/);
    return numericPart ? parseInt(numericPart[0]) : 0;
  }

  function scrollToTop() {
    window.scrollTo({
      top: 0,
    });
  }

  const goToPage = (pageNumber: number) => {
    scrollToTop();
    setCurrentPage(pageNumber);
  };

  const goToChapter = (chapterName: string) => {
    scrollToTop();
    navigate(`/manga/${mangaTitle}/chapitre/${chapterName}`, {
      state: location.state,
    });
    setCurrentPage(0);
  };

  const getCurrentChapterIndex = (): number => {
    const currentChapterName = location.pathname.split('/')[4];
    return mangaChapters?.findIndex((chapter: MangaChapter) => chapter.chapter_name === currentChapterName) ?? 0;
  };

  const renderPageSelector = () => {
    if (!mangaChapters || mangaChapters.length === 0) {
      return null;
    }

    const totalPages = mangaChapters[getCurrentChapterIndex()]?.chapter_pages?.length ?? 0;
    const pageOptions = Array.from({ length: totalPages }, (_, index) => index + 1);

    return (
      <select value={currentPage + 1} onChange={(e) => goToPage(parseInt(e.target.value) - 1)}>
        {pageOptions.map((page) => (
          <option key={page} value={page}>
            Page {page}
          </option>
        ))}
      </select>
    );
  };

  const renderChapterSelector = () => {
    if (!mangaChapters || mangaChapters.length === 0) {
      return null;
    }

    // Trier les chapitres par ordre croissant en utilisant parseFloat
    const sortedChapters = mangaChapters.sort((a: MangaChapter, b: MangaChapter) =>
      parseFloat(a.chapter_name) - parseFloat(b.chapter_name)
    );

    return (
      <select value={location.pathname.split('/')[4]} onChange={(e) => goToChapter(e.target.value)}>
        {sortedChapters.map((chapter: MangaChapter) => (
          <option key={chapter.chapter_name} value={chapter.chapter_name}>
            Chapitre {chapter.chapter_name}
          </option>
        ))}
      </select>
    );
  };

  const renderImage = () => {
    const currentChapter = location.pathname.split('/')[4];
    const currentChapterIndex = mangaChapters?.findIndex((chapter: MangaChapter) => chapter.chapter_name === currentChapter);

    if (currentChapterIndex !== undefined && currentChapterIndex > -1) {
      const imageUrl = 'https://www.devcarlscan-api.devcarl.fr/' + mangaChapters[currentChapterIndex].chapter_pages[currentPage];
      return <img src={imageUrl} id={currentChapterIndex + 1} alt={`Page ${currentPage + 1}`} />;
    }

    return null;
  };

  const renderAllImages = () => {
    const currentChapter = location.pathname.split('/')[4];
    const currentChapterIndex = mangaChapters?.findIndex((chapter: MangaChapter) => chapter.chapter_name === currentChapter);

    if (currentChapterIndex !== undefined && currentChapterIndex > -1) {
      const imageUrl: string[] = mangaChapters[currentChapterIndex].chapter_pages;
      imageUrl.sort((a: string, b: string) => {
        const numA = parseInt(a.match(/\/(\d+)\.jpg/)![1]);
        const numB = parseInt(b.match(/\/(\d+)\.jpg/)![1]);

        if (numA < numB) {
          return -1;
        } else if (numA > numB) {
          return 1;
        } else {
          return 0;
        }
      });

      return imageUrl.map((image, i) => (
        <img src={`https://www.devcarlscan-api.devcarl.fr${image}`} alt={`Page ${i + 1}`} id={`${i + 1}`} key={i} />
      ));
    }

    return null;
  };


  return (
    <div className='chapter-wrapper'>
      <Link className='back-home' to={`/`}>&#8701; Accueil</Link>
      <div onClick={() => setFullMod(!fullMod)} className={`mode-verticale ${fullMod ? "green" : ""}`}>
        MV
      </div>
      <div className='input-wrapper'>
        <div className='selector-wrapper'>
          {renderChapterSelector()}
          <div className={`render-page-selector ${fullMod ? "disabled" : ""}`}>
            {renderPageSelector()}
          </div>
        </div>
        <div className='page-input-wrapper'>
          <button
            className={`btn-page-prev ${fullMod ? "disabled" : ""}`}
            onClick={() => goToPage(currentPage - 1)}
            disabled={currentPage === 0}
          >
            &#8701;
          </button>
          <button
            className={`btn-page-next ${fullMod ? "disabled" : ""}`}
            onClick={() => goToPage(currentPage + 1)}
            disabled={!mangaChapters || currentPage === (mangaChapters?.[getCurrentChapterIndex()]?.chapter_pages?.length ?? 0) - 1}
          >
            &#8702;
          </button>
        </div>
      </div>
      <div className={`image-wrapper ${fullMod ? "full-wrapper" : "single-wrapper"}`}>
        {!fullMod ? renderImage() : renderAllImages()}
      </div>
      <div className='page-wrapper'>
        <p className={`pages-nbr ${fullMod ? "disabled" : ""}`}>
          Page {currentPage + 1} sur {mangaChapters?.[getCurrentChapterIndex()]?.chapter_pages?.length}
        </p>
        <button
          onClick={() => {
            const currentChapterIndex = getCurrentChapterIndex();
            if (currentChapterIndex > 0) {
              const previousChapter = mangaChapters[currentChapterIndex - 1].chapter_name;
              goToChapter(previousChapter);
            }
          }}
          disabled={!mangaChapters || getCurrentChapterIndex() === 0}
        >
          Chapitre précédent
        </button>
        <button
          onClick={() => {
            const currentChapterIndex = getCurrentChapterIndex();
            if (currentChapterIndex < mangaChapters.length - 1) {
              const nextChapter = mangaChapters[currentChapterIndex + 1].chapter_name;
              goToChapter(nextChapter);
            }
          }}
          disabled={!mangaChapters || getCurrentChapterIndex() === mangaChapters.length - 1}
        >
          Chapitre suivant
        </button>
      </div>
    </div>
  );
};

export default Chapitre;
